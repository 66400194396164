import Swiper from 'swiper';
import { Navigation, Pagination, Scrollbar, EffectFade, Autoplay, Mousewheel, Controller } from 'swiper/modules';
Swiper.use([Navigation, Pagination, Scrollbar, EffectFade, Autoplay, Mousewheel, Controller]);
import { Modal } from "bootstrap";


$(() => {
  const galleyModals = document.querySelectorAll('.modal-gallery')

  galleyModals.forEach((modal) => {
    const swiperClass = modal.querySelector('.swiper-gallery')
    const swiper = new Swiper(swiperClass, {
      loop: true,
      spaceBetween: 20,
      autoHeight: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
    })

    modal.addEventListener('shown.bs.modal', () => {
      swiper.updateSize()
    })
  })
})