import { Popover } from 'bootstrap'

const hasPc = window.matchMedia('(min-width: 768px)')

$(() => {
  const popoverShowroomTriggerList = [].slice.call(document.querySelectorAll('.popover-btn-showroom'))
  const popoverShowroomList = popoverShowroomTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl, {
      customClass: 'popover-showroom',
      placement: 'auto',
      html: true,
      offset: [0, 16],
      template: `
        <div class="popover" role="tooltip">
          <div class="popover-arrow"></div>
          <div class="popover-body"></div>
        </div>
      `,
    })
  })

  const popoverAreaMapTriggerList = [].slice.call(document.querySelectorAll('.popover-area-pin'))
  const popoverAreaMapList = popoverAreaMapTriggerList.map( (popoverTriggerEl) => {
    const popoverId =  popoverTriggerEl.getAttribute('href')
    const popoverContent = document.querySelector(popoverId).cloneNode(true);
    popoverContent.id = ''
    return new Popover(popoverTriggerEl, {
      customClass: 'popover-area-map',
      placement: 'auto',
      html: true,
      offset: [0, 16],
      content: popoverContent,
      template: `
        <div class="popover" role="tooltip">
          <div class="popover-arrow"></div>
          <div class="popover-body"></div>
        </div>
      `,
    })
  })

  $(popoverAreaMapTriggerList).on('click', function(e) {
    if(hasPc.matches) {
      e.preventDefault()
    }
  })
})