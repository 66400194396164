import Swiper from 'swiper';
import { Navigation, Pagination, Scrollbar, EffectFade, Autoplay, Mousewheel, Controller } from 'swiper/modules';
Swiper.use([Navigation, Pagination, Scrollbar, EffectFade, Autoplay, Mousewheel, Controller]);

import "./swiperManager"
import SwiperManager from './swiperManager';

$(() => {
  const voiceSwiper = new Swiper('.voice-swiper', {
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
      }
    },
  })
})
