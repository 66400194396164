import { Offcanvas } from "bootstrap"

$(() => {
  const $navbarOffcanvasBtn = $('.btn-menu')
  const navbarOffcanvas = document.getElementById('offcanvasNavbar')
  const navbarOffcanvasInstance = new Offcanvas(navbarOffcanvas)

  navbarOffcanvas.addEventListener('show.bs.offcanvas', () => {
    $navbarOffcanvasBtn.attr('aria-expanded', 'true')
  })

  navbarOffcanvas.addEventListener('hide.bs.offcanvas', () => {
    $navbarOffcanvasBtn.attr('aria-expanded', 'false')
  })

  const $offcanvasNavbarLink = $('#offcanvasNavbar .navbar-nav .nav-link')

  $offcanvasNavbarLink.on('click', () => {
    navbarOffcanvasInstance.hide()
  })
})

$(() => {
  const $navbarOffcanvasBtn = $('.navbar-toggler')
  const navbarOffcanvas = document.getElementById('navbar-offcanvas')
  const navbarOffcanvasInstance = new Offcanvas(navbarOffcanvas)

  navbarOffcanvas.addEventListener('show.bs.offcanvas', () => {
    $navbarOffcanvasBtn.attr('aria-expanded', 'true')
  })

  navbarOffcanvas.addEventListener('hide.bs.offcanvas', () => {
    $navbarOffcanvasBtn.attr('aria-expanded', 'false')
  })

  const $offcanvasNavbarLink = $('.navbar .navbar-nav .nav-link')

  // $offcanvasNavbarLink.on('click', () => {
  //   navbarOffcanvasInstance.hide()
  // })
})